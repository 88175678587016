






























import { Component, Mixins } from 'vue-property-decorator'

import { Inject } from '../../../../support'
import { AbstractModuleUi } from '../../../abstract/ui'

import {
  NewsletterFormState,
  NewsletterModule
} from '../../Newsletter.contracts'
import {
  IUserComService,
  userComConfig,
  UserComServiceType
} from '../../../../front/shared/services'
import { NewsletterModuleMixin } from '../Newsletter.mixin'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<Default>({
  name: 'Default',
  created () {
    if (this.userComConfig.apiKey.length && this.userComConfig.endpoint.length) {
      this.isUserComAvailable = true
    }
  }

})
export class Default extends Mixins<AbstractModuleUi<NewsletterModule>, NewsletterModuleMixin>(AbstractModuleUi, NewsletterModuleMixin) {
  @Inject(UserComServiceType)
  protected readonly newsletterService?: IUserComService

  public isUserComAvailable: boolean = false
  public userComConfig = userComConfig

  /**
   * Subscribes to newsletter
   */
  public async subscribe (): Promise<void> {
    if (!this.newsletterService) {
      return
    }

    try {
      this.isLoading = true
      this.newsletterService.subscribe(this.formData, this.data.content.eventName)
      this.state = NewsletterFormState.Success
      this.message = this.$t('modules.Newsletter.form.state.success').toString()
    } catch (e) {
      this.state = NewsletterFormState.Error
      this.message = this.$t((e as Error).message).toString()
    } finally {
      this.isLoading = false
    }
  }
}

export default Default
