





import { Component } from 'vue-property-decorator'
import { VueConstructor } from 'vue'

import { INewsletterService, NewsletterServiceType } from '../../../front/shared/services/newsletter'
import { Inject } from '../../../support'

import { AbstractModuleUi } from '../../abstract/ui'

import { NewsletterModule } from '../Newsletter.contracts'
import {
  NEWSLETTER_MODULE_CONFIG_MAP,
  NEWSLETTER_MODULE_KEY,
  NewsletterModuleLayout,
  newsletterModuleVersionsRegistry
} from '../Newsletter.config'
import Default from './versions/Default.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl> (edited)
 */
@Component<NewsletterModuleUi>({
  name: 'Newsletter',
  created () {
    this.config = this.getComponentConfig(
      NEWSLETTER_MODULE_KEY,
      { ...NEWSLETTER_MODULE_CONFIG_MAP }
    )
  }
})
export class NewsletterModuleUi extends AbstractModuleUi<NewsletterModule> {
  @Inject(NewsletterServiceType)
  protected readonly newsletterService!: INewsletterService

  /**
   * Determines component version.
   */
  public get component (): VueConstructor {
    const layout: string = this.getConfigProperty<string>('layout')
    if (!Object.values(NewsletterModuleLayout).includes(layout as NewsletterModuleLayout)) {
      return Default
    }
    return newsletterModuleVersionsRegistry[layout]
  }
}

export default NewsletterModuleUi
