




























import { Component, Mixins } from 'vue-property-decorator'

import { AbstractModuleUi } from '../../../abstract/ui'

import { NewsletterFormState, NewsletterModule } from '../../Newsletter.contracts'

import { NewsletterModuleMixin } from '../Newsletter.mixin'

/**
 * @author Maciej Perzankowski <maciej.perzankowski@movecloser.pl>
 */
@Component({
  name: 'Rich'
})
export class Rich extends Mixins<AbstractModuleUi<NewsletterModule>, NewsletterModuleMixin>(AbstractModuleUi, NewsletterModuleMixin) {
  /**
   * Subscribes to newsletter
   */
  public async subscribe (): Promise<void> {
    try {
      this.isLoading = true

      const privacy = document.querySelector<HTMLInputElement>('#privacy-policy')
      if (privacy!.checked) {
        this.eventBus.emit('app:newsletter.subscribe', this.formData)

        this.state = NewsletterFormState.Success
        this.message = this.$t('modules.Newsletter.form.state.success').toString()
      } else {
        this.state = NewsletterFormState.Error
        this.message = this.$t('modules.Newsletter.form.state.checkCheckbox').toString()
        this.errors.push('email')
      }
    } catch (e) {
      this.state = NewsletterFormState.Error
      this.message = this.$t((e as Error).message).toString()
    } finally {
      this.isLoading = false
    }
  }
}

export default Rich
